<template>
  <InfoWraper>
    <Support />
   
  </InfoWraper>
</template>

<script>
import { InfoWraper } from "./auth-info-style";
import Support from "./Support";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { defineComponent } from "vue";

export default defineComponent({
  name: "AuthInfo",
  components: {
    InfoWraper,
    Support,
  },
  data() {
    return {
      flag: "english",
    };
  },
  setup() {
    const { dispatch } = useStore();
    const { push } = useRouter();
    const SignOut = (e) => {
      e.preventDefault();
      push("/auth/login");
      dispatch("logOut");
    };

    return {
      SignOut,
    };
  },
  methods: {
    onFlagChangeHandle: function (value) {
      this.flag = value;
    },
  },
});
</script>
